import React, { Component } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { StaticImage } from "gatsby-plugin-image"
import "../styles/LogoCarousel.scss"

// const responsive = {
//   0: { items: 1 },
//   650: { items: 2 },
//   850: { items: 3 },
//   1200: { items: 4 },
//   1600: { items: 5 },
//   2000: { items: 6 },
// }

// const settings = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 3,
//   slidesToScroll: 3,
// }

//Carousel for Our Partners
//const LogoCarousel = () => (
// <AliceCarousel
//   autoPlay
//   // autoPlayControls
//   autoPlayStrategy="none"
//   autoPlayInterval={1000}
//   animationDuration={1000}
//   animationType="fadeout"
//   infinite
//   mouseTracking
//   responsive={responsive}
//   // disableButtonsControls
//   disableDotsControls
// >
//   {/* <a href="https://www.cloudbolt.io" target="_blank"> */}
//   <img src={Cloudbolt} className="carousel-logo" />
//   {/* </a>
//   <a href="https://www.vmware.com" target="_blank"> */}
//   <img src={Vmware} className="carousel-logo" />
//   {/* </a>
//   <a href="https://www.spectro.com" target="_blank"> */}
//   <img src={Spectro} className="carousel-logo" />
//   {/* </a>
//   <a href="https://www.netbraintech.com" target="_blank"> */}
//   <img src={Netbrain} className="carousel-logo" />
//   {/* </a>
//   <a href="https://microsoft.com" target="_blank"> */}
//   <img src={Microsoft} className="carousel-logo" />
//   {/* </a> */}
//   {/* <a href="https://morpheusdata.com">*/}
//   <img src={Morpheus} className="carousel-logo" />
//   {/* </a>  <a href="https://www.appdynamics.com" target="_blank"> */}
//   <img src={AppDynamics} className="carousel-logo" />
//   {/* </a>
//   <a href="https://www.dynatrace.com" target="_blank"> */}
//   <img src={Dynatrace} className="carousel-logo" />
//   {/* </a>
//   <a href="https://www.dynatrace.com" target="_blank"> */}
//   <img src={PMG} className="carousel-logo" />
//   <img src={Cisco} className="carousel-logo" />
//   {/* </a>  */}
// </AliceCarousel>
export default class LogoCarousel extends Component {
  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      //autoplay: true,
      autoplaySpeed: 1500,
      arrows: true,
      dots: true,
      responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 850,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    }
    return (
      <div>
        <Slider {...settings}>
          <div className="slide">
            <StaticImage
              src="../images/Cloudbolt_Logo.png"
              alt="Cloudbolt logo"
              className="carousel-logo"
              placeholder="blurred"
            />
          </div>
          <div>
            <StaticImage
              src="../images/vmware.png"
              alt="Vmware logo"
              className="carousel-logo"
              placeholder="blurred"
            />
          </div>
          <div>
            <StaticImage
              src="../images/Nutanix_New.svg"
              alt="Nutanix logo"
              className="carousel-logo"
              placeholder="blurred"
              style={{ maxWidth: "200px" }}
            />
          </div>
          <div>
            <StaticImage
              src="../images/spectro-cloud.png"
              alt="Spectro logo"
              className="carousel-logo"
              placeholder="blurred"
            />
          </div>
          <div>
            <StaticImage
              src="../images/netbrainT.png"
              alt="Netbrain logo"
              className="carousel-logo"
              placeholder="blurred"
            />
          </div>
          <div>
            <StaticImage
              src="../images/microsoft.png"
              alt="Microsoft logo"
              className="carousel-logo"
              placeholder="blurred"
            />
          </div>
          <div>
            <StaticImage
              src="../images/morpheus.png"
              alt="Morpheus logo"
              className="carousel-logo"
              placeholder="blurred"
            />
          </div>
          <div>
            <StaticImage
              src="../images/AppDynamics.png"
              alt="AppDynamics logo"
              className="carousel-logo"
              placeholder="blurred"
            />
          </div>
          <div>
            <StaticImage
              src="../images/dynatrace_logo.png"
              alt="Dynatrace logo"
              className="carousel-logo"
              placeholder="blurred"
            />
          </div>
          <div>
            <StaticImage
              src="../images/pmg.png"
              alt="PMG logo"
              className="carousel-logo"
              placeholder="blurred"
            />
          </div>
          <div>
            <StaticImage
              src="../images/cisco.png"
              alt="Cisco logo"
              className="carousel-logo"
              placeholder="blurred"
            />
          </div>
          <div>
            <StaticImage
              src="../images/redhat.png"
              alt="RedHat Logo"
              className="carousel-logo"
              placeholder="blurred"
            />
          </div>
          <div>
            <StaticImage
              src="../images/servicenow.png"
              alt="ServiceNow Logo"
              className="carousel-logo"
              placeholder="blurred"
            />
          </div>
          <div>
            <StaticImage
              src="../images/lacework.png"
              alt="Lacework Logo"
              className="carousel-logo"
              placeholder="blurred"
              style={{ maxWidth: "85%" }}
            />
          </div>
          <div>
            <StaticImage
              src="../images/SailPoint-logo.png"
              alt="Sailpoint Logo"
              className="carousel-logo"
              placeholder="blurred"
              style={{ maxWidth: "85%" }}
            />
          </div>
          <div>
            <StaticImage
              src="../images/SpyCloud-Logo-Black.png"
              alt="Spycloud Logo"
              className="carousel-logo"
              placeholder="blurred"
              style={{ maxWidth: "85%" }}
            />
          </div>
          <div>
            <StaticImage
              src="../images/hashicorp.png"
              alt="HashiCorp Logo"
              className="carousel-logo"
              placeholder="blurred"
            />
          </div>
          <div>
            <StaticImage
              src="../images/SailPoint-logo.png"
              alt="Sailpoint Logo"
              className="carousel-logo"
              placeholder="blurred"
              style={{ maxWidth: "85%" }}
            />
          </div>
          <div>
            <StaticImage
              src="../images/SpyCloud-Logo-Black.png"
              alt="Spycloud Logo"
              className="carousel-logo"
              placeholder="blurred"
              style={{ maxWidth: "85%" }}
            />
          </div>
          <div>
            <StaticImage
              src="../images/rezilion.png"
              alt="Rezilion Logo"
              className="carousel-logo"
              placeholder="blurred"
              style={{ maxWidth: "75%" }}
            />
          </div>
          {/* <div>
            <StaticImage
              src="../images/snapt.png"
              alt="Snapt Logo"
              className="carousel-logo"
              placeholder="blurred"
              style={{ maxWidth: "75%" }}
            />
          </div> */}
          <div>
            <StaticImage
              src="../images/delinea.webp"
              alt="Snapt Logo"
              className="carousel-logo"
              placeholder="blurred"
              style={{ maxWidth: "100%" }}
            />
          </div>
          <div>
            <StaticImage
            src="../images/hoxhunt.webp"
            alt="Hoxhunt Logo"
            className="carousel-logo"
            placeholder="blurred"
            style={{maxWidth: "100%"}}
            />
          </div>
        </Slider>
      </div>
    )
  }
}
